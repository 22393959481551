import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import PressCard from '@/components/Article/PressCard';
import BoardSearchBar from '@/components/BoardSearchBar';
import Container from '@/components/Container';
import Row from '@/components/Grid/Row';
import BasicPagination from '@/components/Pagination/BasicPagination';
import Section from '@/components/Section';
import { BOARD_KIND_PRESS } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { getCurrentPageInfo, PageInfo } from '@/helpers/PaginationHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { FC, useCallback, useEffect, useState } from 'react';

const Press: FC<PageProps> = ({ location }) => {
  const qs = queryString.parse(location.search);
  // 검색중일때 현재 페이지 데이터
  const searchCurrentPage = qs.currentPage ? Number(qs.currentPage) : 1;
  const keyword = qs.keyword as string;
  // 기본데이터는 빈배열 (조건에 따라 로드되기 때문)
  const [nodes, setNodes] = useState<BoardVo[]>([]);
  const [currentPageInfo, setCurrentPageInfo] = useState<PageInfo>();

  // 보도자료 목록 로드
  const loadPressList = useCallback(async () => {
    try {
      const {
        totalCount,
        data,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_PRESS],
        page: 1,
        pagePerCount: 6,
        postOrderOption: 'Y',
      });

      // 페이지네이션 정보
      const pressTotalCount = totalCount as any;
      const pageInfo = getCurrentPageInfo({
        totalItemCount: pressTotalCount,
        baseUri: '/about-us/press',
        pageGroupSize: 5,
        currentPage: 1,
        itemsPerPage: 6,
      });
      setCurrentPageInfo(pageInfo);

      // 보도자료 목록
      const pressList = data as any;
      setNodes(pressList);
    } catch (e) {
      console.error(e);
    }
  }, []);

  // 동적 데이터 로드
  const loadPage = useCallback(
    async (targetPage: number, targetKeyword?: string) => {
      const {
        resultCode,
        data: _data,
        totalCount,
      } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [`${BOARD_KIND_PRESS}`],
        keyword: targetKeyword,
        page: targetPage,
        pagePerCount: 6,
        postOrderOption: 'Y',
      });

      const newData = _data as any;

      if (resultCode === `success`) {
        const boardPageInfo = getCurrentPageInfo({
          totalItemCount: totalCount || 0,
          currentPage: targetPage,
          pageGroupSize: 5,
          itemsPerPage: 6,
          baseUri: '/about-us/press',
          keyword: targetKeyword,
        });

        setNodes(newData);
        setCurrentPageInfo(boardPageInfo);
      }
    },
    [],
  );

  // keyword 변경되면 업데이트
  useEffect(() => {
    if (keyword) {
      loadPage(searchCurrentPage, keyword as string);
    } else {
      loadPressList();
    }
  }, [searchCurrentPage, keyword, loadPage, loadPressList]);

  return (
    <LayoutWithTitle
      location={location}
      title="보도자료"
      description="for every child, press release"
    >
      <Section id="pressSection">
        <Container
          css={`
            padding-top: 96px;

            ${breakpoint(640)} {
              padding-top: 48px;
            }
          `}
        >
          <Row searchBar justifyContent="flex-end">
            <BoardSearchBar
              initialKeyword={keyword}
              onSearch={(value) => {
                navigate(
                  `${currentPageInfo?.baseUri}?${queryString.stringify({
                    keyword: value,
                  })}#pressSection`,
                );
              }}
            />
          </Row>
          <Row boardlist>
            <PressCard
              pressList={nodes}
              queryString={location.search}
              basePath="/about-us/press"
            />
          </Row>
          <Row
            justifyContent="center"
            css={`
              margin-top: 64px;
            `}
          >
            <BasicPagination {...currentPageInfo} anchor="#pressSection" />
          </Row>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default Press;

import { BoardCardLayout } from '../Css/Board';
import { Tit } from '../Titles';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { usePopupStore } from '@/stores/PopupStore';
import dayjs from 'dayjs';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

const H2Title = styled.h2`
  display: inline-block;
  line-height: 1.56;
  letter-spacing: -0.8px;
  font-size: 20px;
  ${breakpoint(`tablet`)} {
    font-size: 16px;
  }
`;

interface PressProps {
  pressList: BoardVo[];
  basePath: string;
  queryString: string;
}

const PressCard: FC<PressProps> = ({ basePath, queryString, pressList }) => {
  const popupStore = usePopupStore();

  const date = new Date();
  const year = date.getFullYear();
  const month = `0${1 + date.getMonth()}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  const today = year + month + day;

  const updateViewCnt = async (boardIndexNumber: string) => {
    const {
      resultCode,
      resultMessage,
    } = await BoardCommonControllerService.viewCntUsingPost({
      boardIndexNumber,
    });

    if (resultCode !== `success`) {
      if (resultMessage !== undefined) {
        popupStore.show(resultMessage);
      } else {
        console.error(
          `보도자료: ${boardIndexNumber} 조회수 증가를 실패했습니다.`,
        );
      }
    }
  };

  return (
    <>
      <BoardCardLayout>
        {pressList.map((press) => (
          <li key={press.boardIndexNumber}>
            <Link
              to={`${basePath}/${press.boardIndexNumber}${queryString}`}
              // onClick={() => press.boardIndexNumber && updateViewCnt(press.boardIndexNumber)}
            >
              <div className="img-wrap">
                <div
                  className="img-box"
                  css={`
                    background-image: url(${press.imgAttGrpNoTn});
                  `}
                />
              </div>
              <div className="dec-wrap">
                <Tit size="s4-1">
                  <H2Title>
                    {press.subject && press.subject.length > 35
                      ? `${press.subject.substr(0, 35)}...`
                      : press.subject}
                  </H2Title>
                  {dayjs(press.dispStartDate, `YYYYMMDD`).diff(
                    today,
                    `month`,
                  ) >= 0 ? (
                    <span className="NewText">new</span>
                  ) : null}
                </Tit>
                <span className="date">
                  {press.dispStartDate &&
                    dayjs(press.dispStartDate, `YYYY.MM.DD`).format(
                      `YYYY.MM.DD`,
                    )}
                </span>
              </div>
            </Link>
          </li>
        ))}
      </BoardCardLayout>
    </>
  );
};
export default PressCard;
